import React, { FC, useEffect, useState } from 'react';
import VimSdkClient from '@getvim/utils-vim-sdk-client';
import { registerWidget, sendDataToApp } from '@getvim/utils-vim-connect-communication';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { isQueryParamUndefined } from '../../utils';
import { VimSelectProviderData, WidgetDataEvent } from '../../types';
import { convertSelectedProvider } from '../../formatting-response-provider';

const supportedWidgets: any = ['SEARCH_AND_ACTION', 'SCHEDULING'];
const widgetSize = 'LARGE';

const SearchProviderWidget: FC = () => {
  const { search: queryString } = useLocation();
  const { env, sdkKey } = parse(queryString);
  const [sdkInit, setSdkInit] = useState(false);

  useEffect(() => {
    if (isQueryParamUndefined(sdkKey) || isQueryParamUndefined(env)) {
      console.log(`couldn't not initialize vim sdk without params`);
      return;
    }
    VimSdkClient.init(sdkKey as string, {
      env: env as any,
      supportedWidgets,
    }).then(() => {
      console.log('init search provider sdk', { env, sdkKey });
      setSdkInit(true);
    });
  }, []);

  registerWidget(
    async ({
      taxonomy,
      address,
      analyticsId,
      icd,
      insurer,
      memberPhoneNumber,
      memberToken,
    }: VimSelectProviderData) => {
      if (!sdkInit) {
        console.log('search provider sdk didnt init', { env, sdkKey });
        return;
      }
      try {
        console.log('opening search provider sdk (widget log)');
        const SearchQuery = {
          filters: { taxonomy },
          icd,
          geo: { address },
          insurer,
        };
        const options = { memberToken, memberPhoneNumber, analyticsId, widgetSize };
        const onSelect = (sdkProvider: any) => {
          if (!sdkProvider) return;
          const { ranking } = sdkProvider;
          const provider = convertSelectedProvider(sdkProvider);
          sendDataToApp({
            event: WidgetDataEvent.ON_SELECTED_PROVIDER,
            provider,
            ranking,
          });
        };

        await VimSdkClient.selectProvider(SearchQuery, onSelect, options);

        console.log('sdk closed');
        sendDataToApp({ event: WidgetDataEvent.ON_SDK_CLOSED });
      } catch (error) {
        sendDataToApp({ event: WidgetDataEvent.ON_SDK_CLOSED });

        if (error) {
          console.log({ error });
          return;
        }

        console.log('sdk closed');
      }
    },
  );

  return <div />;
};

export default SearchProviderWidget;
