import '@getvim/atomic-ui/assets/styles/main.less';
import './App.less';
import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import SelectProviderInlineWidget from './components/inlineWidget/select-provider-inline-widget';
import SearchProviderWidget from './components/searchProviderWidget/search-provider-widget';
import SelectProviderOverlayWidget from './components/overlayWidget/select-provider-overlay-widget';

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/select-provider-inline-widget">
          <SelectProviderInlineWidget />
        </Route>
        <Route path="/select-provider-overlay-widget">
          <SelectProviderOverlayWidget />
        </Route>
        <Route path="/search-provider">
          <SearchProviderWidget />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
