import React, { FC, useEffect, useState } from 'react';
import { registerWidget, sendDataToApp } from '@getvim/utils-vim-connect-communication';
import './selector-provider-widget-inline.css';
import { AppToInlineWidgetPayload, WidgetDataEvent } from '../../types';

const SelectProviderInlineWidget: FC = () => {
  const [shouldDisplay, setShouldDisplay] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    registerWidget((data: AppToInlineWidgetPayload) => {
      const { isLoadingWidget, shouldDisplayWidget } = data;
      // eslint-disable-next-line no-console
      console.log('Data arrived from app:', { data });
      setShouldDisplay(shouldDisplayWidget);
      setIsLoading(isLoadingWidget);
    });
  }, []);

  return shouldDisplay ? (
    <div>
      {isLoading ? (
        <div className="vim-loading-id widget-inline-size">
          <img src="https://static.getvim.com/img/dotsLoader.svg" alt="loading logo" />
        </div>
      ) : (
        <button
          type="button"
          className="vim-button-id widget-inline-size"
          onClick={() => {
            sendDataToApp({ event: WidgetDataEvent.OPEN_SEARCH_PROVIDER });
          }}
        >
          Select with
          <img src="https://static.getvim.com/img/logos/VimLogox2.png" alt="vim logo" />
        </button>
      )}
    </div>
  ) : null;
};

export default SelectProviderInlineWidget;
