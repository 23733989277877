interface BaseAppToWidgetPayload {
  isLoadingWidget: boolean;
}

export interface AppToInlineWidgetPayload extends BaseAppToWidgetPayload {
  shouldDisplayWidget: boolean;
}

export type AppToOverlayWidgetPayload = BaseAppToWidgetPayload;

export enum WidgetDataEvent {
  'ON_SELECTED_PROVIDER' = 'on-selected-provider',
  'ON_SDK_CLOSED' = 'on-sdk-closed',
  'OPEN_SEARCH_PROVIDER' = 'open-search-provider',
}

export interface VimSelectProviderData {
  taxonomy: string | string[];
  icd: string[];
  address?: string;
  insurer: string;
  memberToken: string;
  memberPhoneNumber?: string;
  analyticsId: string;
}

export interface SelectedProviderSdkResponse {
  address: string;
  firstName: string;
  lastName: string;
  middleName: string | undefined;
  npi: string;
  fax: { number: string; countryDialingCode: string };
  phoneNumber: { number: string; countryDialingCode: string };
  taxonomies: TaxonomiesSdkResponse[];
  ranking: number;
}

export interface TaxonomiesSdkResponse {
  subSpecialtyDescription: string;
  taxonomyDisplayName: string;
  taxonomyCode: string;
}

export interface ProviderPayload {
  npi?: string;
  demographics: {
    firstName: string;
    lastName: string;
    middleName?: string;
  };
  organization?: {
    address?: AddressProviderPayload;
    contact_info?: {
      mobilePhoneNumber?: string;
      faxNumber?: string;
    };
  };
  specialty?: Array<{ description?: string; taxonomies?: string[] }>;
}

export interface AddressProviderPayload {
  fullAddress?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
}
