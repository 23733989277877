import { AddressProviderPayload, ProviderPayload, SelectedProviderSdkResponse } from './types';

export const convertSelectedProvider = (
  sdkProvider: SelectedProviderSdkResponse,
): ProviderPayload => {
  const {
    address: fullAddress,
    firstName,
    middleName,
    lastName,
    npi,
    taxonomies,
    fax,
    phoneNumber,
  } = sdkProvider;

  const specialty = taxonomies?.map(({ subSpecialtyDescription, taxonomyCode }) => {
    return { description: subSpecialtyDescription, taxonomies: [taxonomyCode] };
  });

  return {
    npi,
    demographics: { firstName, middleName, lastName },
    organization: {
      address: convertFullAddressToProviderAddress(fullAddress),
      contact_info: { mobilePhoneNumber: phoneNumber?.number, faxNumber: fax?.number },
    },
    specialty,
  };
};

const convertFullAddressToProviderAddress = (address: string): AddressProviderPayload => {
  const splitAddress = address.split(',').map((s) => s.trim());
  const lastScope = splitAddress[splitAddress.length - 1];
  const splitStateAndZipCode = lastScope.split(' ');
  const city = splitAddress[splitAddress.length - 2] || '';
  const address1 = splitAddress[0] || '';
  splitAddress.splice(splitAddress.length - 2, 2); // removing city, state & zip code
  splitAddress.splice(0, 1); // removing address 1
  const address2 = splitAddress.join(', ') || ''; // adding all other text inside address 2

  return {
    fullAddress: address,
    address1,
    address2,
    city,
    state: splitStateAndZipCode[0] || '',
    zipCode: splitStateAndZipCode[1] || '',
  };
};
