import React, { FC, useEffect, useState } from 'react';
import { registerWidget, sendDataToApp } from '@getvim/utils-vim-connect-communication';
import { AppToOverlayWidgetPayload, WidgetDataEvent } from '../../types';
import './select-provider-overlay-widget.less';

const SelectProviderOverlayWidget: FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    registerWidget((data: AppToOverlayWidgetPayload) => {
      const { isLoadingWidget } = data;
      // eslint-disable-next-line no-console
      console.log('Data arrived from app:', { data });
      setIsLoading(isLoadingWidget);
    });
  }, []);

  return (
    <div className="expand-button">
      {isLoading ? (
        <div className="vim-loading-id widget-overlay-size">
          <img src="https://static.getvim.com/img/dotsLoader.svg" alt="loading logo" />
        </div>
      ) : (
        <button
          type="button"
          className="expand-button-inner"
          onClick={() => {
            sendDataToApp({ event: WidgetDataEvent.OPEN_SEARCH_PROVIDER });
          }}
        >
          Select with
          <img src="https://static.getvim.com/img/logos/VimIcon.png" alt="vim logo" />
        </button>
      )}
    </div>
  );
};

export default SelectProviderOverlayWidget;
